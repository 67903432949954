import { ReactNode, useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import {
  browserTracingIntegration,
  captureConsoleIntegration,
  init as SentryInit,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { QueryClientProvider } from '@tanstack/react-query';
import { onAuthStateChanged, Unsubscribe, User } from 'firebase/auth';
import { debounce } from 'lodash';

import { useTitle } from './hooks/useTitle';
import { BeamoProvider } from './services/beamo/provider';
import { queryClient } from './services/beamo/queryClient';
import { auth, checkCookieCredentialPromise } from './services/firebaseAuth';
import {
  isOfframpV2Module,
  isPaymentApp,
  isPayModule,
  isPayV2Module,
  isWalletV2Module,
  moduleName,
} from './utils/apexModuleHelper';
import { filterAndClassifySentryError, SENTRY_DENY_URLS, SENTRY_IGNORE_ERRORS } from './utils/commonErrors';
import GlobalLoader from './GlobalLoader';
import { store } from './store';
// don't delete
// import './mock';

const environment =
  process.env.REACT_APP_DEPLOY_TAG === 'prod'
    ? 'production'
    : process.env.REACT_APP_DEPLOY_TAG === 'qa' || process.env.REACT_APP_DEPLOY_TAG === 'devdp'
      ? 'development'
      : 'localhost';

if (process.env.NODE_ENV !== 'development') {
  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment,
    integrations: [
      browserTracingIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    initialScope: {
      tags: {
        module: moduleName,
        priority: 'P1', //set to high priority until further user review
      },
    },
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/[^/]+\.breeze.cash\//],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    tracesSampler(context) {
      switch (context.tags?.priority) {
        case 'P0':
          return 1; // 100% for P0
        case 'P1':
          return 0.5; // 50% for P1
        default:
          return 0.1;
      }
    },
    beforeSend(event, hint = {}) {
      const originalException = hint.originalException as Error;
      const errorFiltered = filterAndClassifySentryError(originalException);
      if (!errorFiltered) {
        return null;
      }

      if (errorFiltered.fingerprint) event.fingerprint = errorFiltered.fingerprint;
      if (errorFiltered.priority) {
        event.tags = {
          ...event.tags,
          priority: event.tags?.priority || errorFiltered.priority,
        };
      }

      return event;
    },
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    denyUrls: SENTRY_DENY_URLS,
  });
}

const onResize = debounce(() => {
  if (window.innerWidth < 768) {
    document.body.classList.add('mobile');
    document.body.classList.remove('pc');
  } else {
    document.body.classList.remove('mobile');
    document.body.classList.add('pc');
  }
}, 80);
window.addEventListener('resize', onResize);
onResize();

function StatsProvider({ children, userId, userEmail }: { children: ReactNode; userId?: string; userEmail?: string }) {
  const { client } = useClientAsyncInit(
    process.env.STATSIG_CLIENT_KEY as string,
    {
      userID: userId || undefined, //use default statsig user id
      locale: 'en',
      email: userEmail,
    },
    {
      environment: {
        tier: environment,
      },
    }
  );

  useEffect(() => {
    runStatsigAutoCapture(client);
    runStatsigSessionReplay(client);
  }, [client]);

  return (
    <StatsigProvider
      client={client}
      loadingComponent={<GlobalLoader />}
      options={{
        loggingIntervalMs: 5000,
        networkConfig: {
          networkTimeoutMs: 1000,
        },
      }}
    >
      {children}
    </StatsigProvider>
  );
}

function Root({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(auth.currentUser);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useTitle('Breeze');

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    const signin = async () => {
      await checkCookieCredentialPromise;

      unsubscribe = onAuthStateChanged(auth, async (_user) => {
        setUser(_user);
        console.log('[Breeze] Set user in App.tsx,', _user?.email, _user?.uid);
        setIsLoading(false);
      });
    };

    signin();

    return () => {
      unsubscribe?.();
    };
  }, []);

  //remove Div100vh, use env safe area instead.
  //To avoid breaking other modules, only apply to payv2
  const DivContainer = isPayModule || isPayV2Module || isOfframpV2Module ? 'div' : Div100vh;

  return (
    <DivContainer>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BeamoProvider key={user?.uid} user={user}>
            {isLoading ? (
              <GlobalLoader />
            ) : (
              <StatsProvider userId={user?.uid} userEmail={user?.email || undefined}>
                {children}
              </StatsProvider>
            )}
          </BeamoProvider>
        </Provider>
      </QueryClientProvider>
    </DivContainer>
  );
}

const root = createRoot(document.getElementById('root') as HTMLElement);
if (isPaymentApp) {
  import('./apexModules/payment-page').then((PaymentPageApp) => {
    root.render(
      <Root>
        <PaymentPageApp.default />
      </Root>
    );
  });
} else if (isWalletV2Module) {
  import('./apexModules/wallet-v2').then((WalletV2App) => {
    root.render(<WalletV2App.default />);
  });
} else {
  import('./DashboardApp').then((DashboardApp) => {
    root.render(
      <Root>
        <DashboardApp.default />
      </Root>
    );
  });
}
