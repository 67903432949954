import { captureException, setContext } from '@sentry/react';
import { Keypair } from '@solana/web3.js';
import { TORUS_LEGACY_NETWORK } from '@toruslabs/constants';
import { NodeDetailManager } from '@toruslabs/fetch-node-details';
import { getED25519Key } from '@toruslabs/openlogin-ed25519';
import { subkey } from '@toruslabs/openlogin-subkey';
import { Torus } from '@toruslabs/torus.js';
import { ethers } from 'ethers';
import { User } from 'firebase/auth';
import { retry } from 'ts-retry-promise';

import { getWeb3AuthConfig } from '@/constants/config';
import { store } from '@/store';
import { isDev } from '@/utils';

import { commonApi } from '../beamo/api-common';
import { logBreezeEvent } from '../firebaseAuth';

export type TKeyDetail = {
  PrivateKey: string;
  PublicAddress: string;
};

export enum EChainType {
  EVM = 'EVM',
  SOLANA = 'SOLANA',
}

const { clientId, verifier } = getWeb3AuthConfig();

const nodeDetailManager = new NodeDetailManager({
  network: TORUS_LEGACY_NETWORK.CYAN,
  enableLogging: isDev,
});

const torus = new Torus({
  enableOneKey: true,
  network: TORUS_LEGACY_NETWORK.CYAN,
  clientId,
});

const _getKeystore = async ({
  uid,
  idToken,
}: {
  uid: string;
  idToken: string;
}): Promise<Map<EChainType, TKeyDetail>> => {
  let isNewUser = true;
  try {
    const { wallet } = await commonApi.getAccountInfo();
    if (wallet) {
      isNewUser = false;
    }
  } catch (e) {
    /* empty */
  }
  const { paymentPageData, payment } = store.getState();
  const baseParams = {
    transaction_id: uid,
    uid,
    pageId: paymentPageData?.pageId,
    email: payment?.email,
    paymentFlow: payment?.paymentFlow,
  };
  const startTime = performance.now();
  const eventPrefix = isNewUser ? 'web3auth_new_' : 'web3auth_';
  logBreezeEvent(eventPrefix + 'start', baseParams);
  const { torusNodeEndpoints, torusIndexes, torusNodePub } = await nodeDetailManager.getNodeDetails({
    verifier,
    verifierId: uid,
  });
  logBreezeEvent(eventPrefix + 'getNodeDetails', { ...baseParams, elapsed: performance.now() - startTime });
  // retrieveShares will now generate a new wallet if not exists
  const { finalKeyData } = await torus.retrieveShares({
    endpoints: torusNodeEndpoints,
    indexes: torusIndexes,
    nodePubkeys: torusNodePub,
    verifier,
    verifierParams: {
      verifier_id: uid,
    },
    idToken,
  });
  logBreezeEvent(eventPrefix + 'retrieveShares', { ...baseParams, elapsed: performance.now() - startTime });
  const privKey = finalKeyData.privKey;
  const keystore = new Map<EChainType, TKeyDetail>();
  const finalPrivKey = subkey(privKey!.padStart(64, '0'), Buffer.from(clientId, 'base64')).padStart(64, '0');

  // evm
  const wallet = new ethers.Wallet('0x' + finalPrivKey);
  const evmAddress = wallet.address;
  keystore.set(EChainType.EVM, { PrivateKey: finalPrivKey, PublicAddress: evmAddress });

  // solana
  const solanaPrivKey = getED25519Key(finalPrivKey).sk.toString('hex');
  const keypair = Keypair.fromSecretKey(Buffer.from(solanaPrivKey, 'hex'));
  const solanaAddress = keypair.publicKey.toBase58();
  keystore.set(EChainType.SOLANA, { PrivateKey: solanaPrivKey, PublicAddress: solanaAddress });

  const pks = {
    solanaAddress,
    evmAddress,
    torusAddress: finalKeyData.walletAddress,
  };
  setContext('publicKey', pks);

  if (isNewUser) {
    await commonApi.reportWalletAddress({
      wallet: pks,
    });
  }
  logBreezeEvent(eventPrefix + 'reportWalletAddress', { ...baseParams, elapsed: performance.now() - startTime });
  return keystore;
};

let keystoreCache = new Map<EChainType, TKeyDetail>();
let keystorePromise: Promise<Map<EChainType, TKeyDetail>>;
let currentUid = '';
// verifier: web3auth verifier
// verifierId: sub
// idToken: idToken from firebase auth
export const getKeystore = async (user: User): Promise<Map<EChainType, TKeyDetail>> => {
  const { uid } = user;
  if (keystoreCache.size > 0 && currentUid === uid) {
    return keystoreCache;
  }
  if (currentUid !== uid || (keystoreCache.size === 0 && !keystorePromise)) {
    // console.info('create new keystore for ', uid);
    keystoreCache = new Map<EChainType, TKeyDetail>();
    keystorePromise = retry(
      () =>
        user.getIdToken(true).then((idToken) =>
          _getKeystore({ uid, idToken }).catch((e) => {
            const response = e.response || {};
            captureException(e, {
              fingerprint: ['web3auth', 'getKeystore'],
              extra: {
                message: e.message,
                statusText: e.statusText,
                reqHeaders: response.config?.headers,
                reqData: response.config?.data,
                reqUrl: response.config?.url,
                resData: response.data,
              },
            });
            throw e;
          })
        ),
      {
        timeout: 30 * 1000,
        delay: 2000,
      }
    ).catch((e) => {
      const { paymentPageData, payment } = store.getState();
      const baseParams = {
        transaction_id: uid,
        uid,
        pageId: paymentPageData?.pageId,
        email: payment?.email,
        paymentFlow: payment?.paymentFlow,
        error: e.message,
      };
      logBreezeEvent('web3auth_error', baseParams);
      // @ts-expect-error
      keystorePromise = undefined;
      return new Map<EChainType, TKeyDetail>();
    });
    currentUid = uid;
  }
  keystoreCache = await keystorePromise;
  return keystoreCache;
};
