import { EKycStep } from '../kyc-flow/kyc-steps';

import { EOfframp2Step } from './offramp2Steps';
import { EPay2Step } from './pay2Steps';

export const enum ECommonStep {
  // to always show a completed section
  __ALWAYS_COMPLETED = '__ALWAYS_COMPLETED',
  __ALWAYS_LAST = '__ALWAYS_LAST',
}

export type TBreezeStep = EPay2Step | ECommonStep | EOfframp2Step | EKycStep;

export const useSteps = ({
  step,
  activeStep,
  steps,
}: {
  step?: TBreezeStep;
  activeStep: TBreezeStep;
  steps: TBreezeStep[];
}) => {
  const activeIndex = steps.indexOf(activeStep);
  const currentIndex = step === undefined ? -1 : steps.indexOf(step);

  const isHidden = currentIndex === -1;
  const isCurrent = currentIndex === activeIndex;
  const isBeforeCurrent = currentIndex < activeIndex;
  const isAfterCurrent = currentIndex > activeIndex;
  const isLast = currentIndex === steps.length - 1;

  if (step === ECommonStep.__ALWAYS_COMPLETED) {
    return {
      activeIndex,
      isHidden: false,
      isCurrent: false,
      isBeforeCurrent: true,
      isAfterCurrent: false,
      isLast: false,
    };
  }
  if (step === ECommonStep.__ALWAYS_LAST) {
    return {
      activeIndex,
      isHidden: false,
      isCurrent: false,
      isBeforeCurrent: false,
      isAfterCurrent: false,
      isLast: true,
    };
  }

  return {
    activeIndex,
    isHidden,
    isCurrent,
    isBeforeCurrent,
    isAfterCurrent,
    isLast,
  };
};
